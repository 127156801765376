import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
  query: [],
  filteredquery: [],
};

export const loadquerydata = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_QUERY_DATA:
      return { ...state, query: payload };
    case ActionTypes.GET_FILTERED_QUERY:
      return { ...state, filteredquery: payload };
    default:
      return state;
  }
};
