import { loadUserData } from "./UserReducer";

import { loadquerydata } from "./QueryReducer";
import { loadblogdata } from "./BlogReducer";
import { loadhoteldata } from "./HotelReducer";
import { loadtourdata } from "./TourPackageReducer";
import { combineReducers } from "redux";
import changeState from "./CommonReducer";
import { loadReviewData } from "./reviewReducer";
import { loadnewsletterdata } from "./newsletterReducer";
import { loadquerydetaildata } from "./queryDetailsReducer";
import { loadcitydata } from "./CityReducer";


const rootReducers = combineReducers({
  nav: changeState,
  allqueries: loadquerydata,
  detailedquery: loadquerydetaildata,
  allblogs: loadblogdata,
  allhotels: loadhoteldata,
  alltours: loadtourdata,
  allusers: loadUserData,
  allreviews: loadReviewData,
  allnewsletters: loadnewsletterdata,
  allCityData : loadcitydata,
});

export default rootReducers;
