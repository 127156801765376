import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
  querydetails: [],
};

export const loadquerydetaildata = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_QUERY_DETAILS_DATA:
      return { ...state, querydetails: payload };
    default:
      return state;
  }
};
