import { ActionTypes } from "../constants/ActionTypes";

const { combineReducers } = require("redux");

const initialState = {
  newsletters: [],
};

export const loadnewsletterdata = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_NEWSLETTER_DATA:
      return { ...state, newsletters: payload };
    default:
      return state;
  }
};
