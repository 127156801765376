export const ActionTypes = {
  //USER MANAGEMENT
  SET_USER_DATA: "SET_USER_DATA",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  DELETE_USER_DATA: "DELETE_USER_DATA",

  //QUERY_MANAGEMENT
  SET_QUERY_DATA: "SET_QUERY_DATA",
  GET_FILTERED_QUERY: "GET_FILTERED_QUERY",
  UPDATE_QUERY_DATA: "UPDATE_QUERY_DATA",
  DELETE_QUERY_DATA: "DELETE_QUERY_DATA",
  SET_QUERY_DETAILS_DATA: "SET_QUERY_DETAILS_DATA",

  //BLOGS_MANAGEMENT
  SET_BLOG_DATA: "SET_BLOG_DATA",
  SET_BLOG_DETAILS: "SET_BLOG_DETAILS",
  UPDATE_BLOG_DATA: "UPDATE_BLOG_DATA",
  DELETE_BLOG_DATA: "DELETE_BLOG_DATA",

  //HOTEL MANAGEMENT

  SET_HOTEL_DATA: "SET_HOTEL_DATA",
  SET_HOTEL_DETAILS: "SET_HOTEL_DETAILS",
  UPDATE_HOTEL_DATA: "UPDATE_HOTEL_DATA",
  DELETE_HOTEL_DATA: "DELETE_HOTEL_DATA",

  //TOUR PACKAGE

  SET_TOUR_DATA: "SET_TOUR_DATA",
  SET_TOUR_DETAILS: "SET_TOUR_DETAILS",
  UPDATE_ITERNARY_DETAILS: "UPDATE_ITERNARY_DETAILS",
  UPDATE_TOUR_DATA: "UPDATE_TOUR_DATA",
  DELETE_TOUR_DATA: "DELETE_TOUR_DATA",

  //REVIEW
  SET_REVIEW_DATA: "SET_REVIEW_DATA",
  UPDATE_REVIEW_DATA: "UPDATE_REVIEW_DATA",
  DELETE_REVIEW_DATA: "DELETE_REVIEW_DATA",

  //NEWSLETTER
  SET_NEWSLETTER_DATA: "SET_NEWSLETTER_DATA",
  UPDATE_NEWSLETTER_DATA: "UPDATE_NEWSLETTER_DATA",
  DELETE_NEWSLETTER_DATA: "DELETE_NEWSLETTER_DATA",

  //CityDetails
  SET_CITY_DATA: "SET_CITY_DATA",
  UPDATE_CITY_DATA: "UPDATE_CITY_DATA",
  DELETE_CITY_DATA: "DELETE_CITY_DATA",
  SET_CITY_DETAILS: "SET_CITY_DETAILS",
};
