import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducers from "./redux/reducers/index";
// import reducers from "./redux/reducers/index";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const initialState = {
//   sidebarShow: "responsive",
//   asideShow: false,
//   darkMode: false,
// };
// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case "set":
//       return { ...state, ...rest };
//     default:
//       return state;
//   }
// };

const store = configureStore({
  // reducer: changeState,
  // allReducers: allReducers,
  reducer: rootReducers,
});
composeEnhancers(applyMiddleware(thunk));
export default store;
