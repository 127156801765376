import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
  city: [],
  citydetails: [],
};

export const loadcitydata = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_CITY_DATA:
      return { ...state, city: payload };
    case ActionTypes.SET_CITY_DETAILS:
      return { ...state, citydetails: payload };
    default:
      return state;
  }
};
