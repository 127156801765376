import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
  blogs: [],
  blogdetail: [],
};

export const loadblogdata = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_BLOG_DATA:
      return { ...state, blogs: payload };
    case ActionTypes.SET_BLOG_DETAILS:
      return { ...state, blogdetail: payload };
    default:
      return state;
  }
};
