import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
  reviews: [],
};

export const loadReviewData = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_REVIEW_DATA:
      return { ...state, reviews: payload };
    default:
      return state;
  }
};
