import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
  tours: [],
  tourdetail:[]
};

export const loadtourdata = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_TOUR_DATA:
      return { ...state, tours: payload };
    case ActionTypes.SET_TOUR_DETAILS:
      return { ...state, tourdetail: payload };
    default:
      return state;
  }
};
