import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
  hotels: [],
  hoteldetails: [],
};

export const loadhoteldata = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_HOTEL_DATA:
      return { ...state, hotels: payload };
    case ActionTypes.SET_HOTEL_DETAILS:
      return { ...state, hoteldetails: payload };
    default:
      return state;
  }
};
